import {
  init,
  createTracingMixins,
  attachErrorHandler,
  setContext,
  setTag,
  setUser,
  addBreadcrumb,
  type Breadcrumb,
  browserTracingIntegration,
  replayIntegration
} from '@sentry/vue'
import { init as initFullstory, FullStory } from '@fullstory/browser'
import { defineNuxtPlugin, useRuntimeConfig } from '#app'

export default defineNuxtPlugin({
  name: 'monitoring',
  parallel: true,
  setup: (nuxtApp) => {
    const { environment, FULLSTORY_ORG_ID, SENTRY_DSN } =
      useRuntimeConfig().public
    const { vueApp } = nuxtApp

    if (environment !== 'production') return

    initFullstory({ orgId: FULLSTORY_ORG_ID })

    init({
      app: [vueApp],
      dsn: SENTRY_DSN,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
      integrations: [
        browserTracingIntegration({
          router: nuxtApp.$router as any
        }),
        replayIntegration()
      ],
      logErrors: true,
      tracesSampleRate: 1.0,
      environment
    })

    vueApp.mixin(
      createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update']
      })
    )

    attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update']
    })

    return {
      provide: {
        sentrySetContext: (n: string, context: Record<string, unknown>) =>
          setContext(n, context),
        sentrySetUser: (user: User) => {
          setUser(user)
          FullStory('setIdentity', {
            uid: user.id,
            properties: {
              email: user.email,
              displayName: user.fullName,
              roles: user.roles,
              workspaceName: user.workspace?.name
            }
          })
        },
        sentrySetTag: (tagName: string, value: Primitive) =>
          setTag(tagName, value),
        sentryAddBreadcrumb: (breadcrumb: Breadcrumb) =>
          addBreadcrumb(breadcrumb)
      }
    }
  }
})
