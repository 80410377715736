import { default as forgot_45passwordUaF9oDbyV0Meta } from "/opt/buildhome/repo/pages/auth/forgot-password.vue?macro=true";
import { default as loginLsrADI0A5CMeta } from "/opt/buildhome/repo/pages/auth/login.vue?macro=true";
import { default as _91token_93Oy9e00nMWcMeta } from "/opt/buildhome/repo/pages/auth/reset-password/[token].vue?macro=true";
import { default as verify_45emailV3gt0NlsqEMeta } from "/opt/buildhome/repo/pages/auth/verify-email.vue?macro=true";
import { default as internal_45data0pAPxOWyEYMeta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/internal-data.vue?macro=true";
import { default as reportN9oIM4th42Meta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/report.vue?macro=true";
import { default as _91taskId_93p6o3vB4JgCMeta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/results/[taskId].vue?macro=true";
import { default as results4ayY8e2Mf9Meta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/results.vue?macro=true";
import { default as tasksJVXrJXDTgXMeta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/tasks.vue?macro=true";
import { default as editcmtz6VX7VpMeta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit.vue?macro=true";
import { default as tasksjMAtV1n5nFMeta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId]/tasks.vue?macro=true";
import { default as _91researchId_93ZW5N75gYP7Meta } from "/opt/buildhome/repo/pages/dashboard/research/[researchId].vue?macro=true";
import { default as cardsbc59V3xM7FMeta } from "/opt/buildhome/repo/pages/dashboard/research/cards.vue?macro=true";
import { default as indexGkG5oWhvaKMeta } from "/opt/buildhome/repo/pages/dashboard/research/index.vue?macro=true";
import { default as researchGZFRYXuqGuMeta } from "/opt/buildhome/repo/pages/dashboard/research.vue?macro=true";
import { default as dashboard6cxkiptQlsMeta } from "/opt/buildhome/repo/pages/dashboard.vue?macro=true";
import { default as datasourcesU5cspMRs7iMeta } from "/opt/buildhome/repo/pages/datasources.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as internal_45dataxc1MnF3pRFMeta } from "/opt/buildhome/repo/pages/internal-data.vue?macro=true";
import { default as _91token_93TC5UbPD9FOMeta } from "/opt/buildhome/repo/pages/register/accept-invite/[token].vue?macro=true";
import { default as _91token_93v20Tf91TpoMeta } from "/opt/buildhome/repo/pages/register/accept-workspace-request/[token].vue?macro=true";
import { default as accept_45workspace_45request0ZQBU8JGSwMeta } from "/opt/buildhome/repo/pages/register/accept-workspace-request.vue?macro=true";
import { default as confirm_45accountl1rnx0IHdKMeta } from "/opt/buildhome/repo/pages/register/confirm-account.vue?macro=true";
import { default as indexRKmeFaXNHZMeta } from "/opt/buildhome/repo/pages/register/index.vue?macro=true";
import { default as join_45workspaceUhvFm6PJb4Meta } from "/opt/buildhome/repo/pages/register/join-workspace.vue?macro=true";
import { default as new_45workspaceOiDKeKPVfkMeta } from "/opt/buildhome/repo/pages/register/new-workspace.vue?macro=true";
import { default as indexYRXphiFWyiMeta } from "/opt/buildhome/repo/pages/settings/account/index.vue?macro=true";
import { default as notificationsSzxVbhHogNMeta } from "/opt/buildhome/repo/pages/settings/account/notifications.vue?macro=true";
import { default as accountlrlzqC4yMWMeta } from "/opt/buildhome/repo/pages/settings/account.vue?macro=true";
import { default as workspace4fmWmZk1v0Meta } from "/opt/buildhome/repo/pages/settings/workspace.vue?macro=true";
import { default as settings4cDYwTsA31Meta } from "/opt/buildhome/repo/pages/settings.vue?macro=true";
export default [
  {
    name: forgot_45passwordUaF9oDbyV0Meta?.name ?? "auth-forgot-password",
    path: forgot_45passwordUaF9oDbyV0Meta?.path ?? "/auth/forgot-password",
    meta: forgot_45passwordUaF9oDbyV0Meta || {},
    alias: forgot_45passwordUaF9oDbyV0Meta?.alias || [],
    redirect: forgot_45passwordUaF9oDbyV0Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: loginLsrADI0A5CMeta?.name ?? "auth-login",
    path: loginLsrADI0A5CMeta?.path ?? "/auth/login",
    meta: loginLsrADI0A5CMeta || {},
    alias: loginLsrADI0A5CMeta?.alias || [],
    redirect: loginLsrADI0A5CMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Oy9e00nMWcMeta?.name ?? "auth-reset-password-token",
    path: _91token_93Oy9e00nMWcMeta?.path ?? "/auth/reset-password/:token()",
    meta: _91token_93Oy9e00nMWcMeta || {},
    alias: _91token_93Oy9e00nMWcMeta?.alias || [],
    redirect: _91token_93Oy9e00nMWcMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/reset-password/[token].vue").then(m => m.default || m)
  },
  {
    name: verify_45emailV3gt0NlsqEMeta?.name ?? "auth-verify-email",
    path: verify_45emailV3gt0NlsqEMeta?.path ?? "/auth/verify-email",
    meta: verify_45emailV3gt0NlsqEMeta || {},
    alias: verify_45emailV3gt0NlsqEMeta?.alias || [],
    redirect: verify_45emailV3gt0NlsqEMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/auth/verify-email.vue").then(m => m.default || m)
  },
  {
    name: dashboard6cxkiptQlsMeta?.name ?? "dashboard",
    path: dashboard6cxkiptQlsMeta?.path ?? "/dashboard",
    meta: dashboard6cxkiptQlsMeta || {},
    alias: dashboard6cxkiptQlsMeta?.alias || [],
    redirect: dashboard6cxkiptQlsMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: researchGZFRYXuqGuMeta?.name ?? undefined,
    path: researchGZFRYXuqGuMeta?.path ?? "research",
    meta: researchGZFRYXuqGuMeta || {},
    alias: researchGZFRYXuqGuMeta?.alias || [],
    redirect: researchGZFRYXuqGuMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research.vue").then(m => m.default || m),
    children: [
  {
    name: _91researchId_93ZW5N75gYP7Meta?.name ?? "dashboard-research-researchId",
    path: _91researchId_93ZW5N75gYP7Meta?.path ?? ":researchId()",
    meta: _91researchId_93ZW5N75gYP7Meta || {},
    alias: _91researchId_93ZW5N75gYP7Meta?.alias || [],
    redirect: _91researchId_93ZW5N75gYP7Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId].vue").then(m => m.default || m),
    children: [
  {
    name: editcmtz6VX7VpMeta?.name ?? "dashboard-research-researchId-edit",
    path: editcmtz6VX7VpMeta?.path ?? "edit",
    meta: editcmtz6VX7VpMeta || {},
    alias: editcmtz6VX7VpMeta?.alias || [],
    redirect: editcmtz6VX7VpMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit.vue").then(m => m.default || m),
    children: [
  {
    name: internal_45data0pAPxOWyEYMeta?.name ?? "dashboard-research-researchId-edit-internal-data",
    path: internal_45data0pAPxOWyEYMeta?.path ?? "internal-data",
    meta: internal_45data0pAPxOWyEYMeta || {},
    alias: internal_45data0pAPxOWyEYMeta?.alias || [],
    redirect: internal_45data0pAPxOWyEYMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/internal-data.vue").then(m => m.default || m)
  },
  {
    name: reportN9oIM4th42Meta?.name ?? "dashboard-research-researchId-edit-report",
    path: reportN9oIM4th42Meta?.path ?? "report",
    meta: reportN9oIM4th42Meta || {},
    alias: reportN9oIM4th42Meta?.alias || [],
    redirect: reportN9oIM4th42Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/report.vue").then(m => m.default || m)
  },
  {
    name: results4ayY8e2Mf9Meta?.name ?? "dashboard-research-researchId-edit-results",
    path: results4ayY8e2Mf9Meta?.path ?? "results",
    meta: results4ayY8e2Mf9Meta || {},
    alias: results4ayY8e2Mf9Meta?.alias || [],
    redirect: results4ayY8e2Mf9Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/results.vue").then(m => m.default || m),
    children: [
  {
    name: _91taskId_93p6o3vB4JgCMeta?.name ?? "dashboard-research-researchId-edit-results-taskId",
    path: _91taskId_93p6o3vB4JgCMeta?.path ?? ":taskId()",
    meta: _91taskId_93p6o3vB4JgCMeta || {},
    alias: _91taskId_93p6o3vB4JgCMeta?.alias || [],
    redirect: _91taskId_93p6o3vB4JgCMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/results/[taskId].vue").then(m => m.default || m)
  }
]
  },
  {
    name: tasksJVXrJXDTgXMeta?.name ?? "dashboard-research-researchId-edit-tasks",
    path: tasksJVXrJXDTgXMeta?.path ?? "tasks",
    meta: tasksJVXrJXDTgXMeta || {},
    alias: tasksJVXrJXDTgXMeta?.alias || [],
    redirect: tasksJVXrJXDTgXMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/edit/tasks.vue").then(m => m.default || m)
  }
]
  },
  {
    name: tasksjMAtV1n5nFMeta?.name ?? "dashboard-research-researchId-tasks",
    path: tasksjMAtV1n5nFMeta?.path ?? "tasks",
    meta: tasksjMAtV1n5nFMeta || {},
    alias: tasksjMAtV1n5nFMeta?.alias || [],
    redirect: tasksjMAtV1n5nFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/[researchId]/tasks.vue").then(m => m.default || m)
  }
]
  },
  {
    name: cardsbc59V3xM7FMeta?.name ?? "dashboard-research-cards",
    path: cardsbc59V3xM7FMeta?.path ?? "cards",
    meta: cardsbc59V3xM7FMeta || {},
    alias: cardsbc59V3xM7FMeta?.alias || [],
    redirect: cardsbc59V3xM7FMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/cards.vue").then(m => m.default || m)
  },
  {
    name: indexGkG5oWhvaKMeta?.name ?? "dashboard-research",
    path: indexGkG5oWhvaKMeta?.path ?? "",
    meta: indexGkG5oWhvaKMeta || {},
    alias: indexGkG5oWhvaKMeta?.alias || [],
    redirect: indexGkG5oWhvaKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/dashboard/research/index.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: datasourcesU5cspMRs7iMeta?.name ?? "datasources",
    path: datasourcesU5cspMRs7iMeta?.path ?? "/datasources",
    meta: datasourcesU5cspMRs7iMeta || {},
    alias: datasourcesU5cspMRs7iMeta?.alias || [],
    redirect: datasourcesU5cspMRs7iMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/datasources.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internal_45dataxc1MnF3pRFMeta?.name ?? "internal-data",
    path: internal_45dataxc1MnF3pRFMeta?.path ?? "/internal-data",
    meta: internal_45dataxc1MnF3pRFMeta || {},
    alias: internal_45dataxc1MnF3pRFMeta?.alias || [],
    redirect: internal_45dataxc1MnF3pRFMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/internal-data.vue").then(m => m.default || m)
  },
  {
    name: _91token_93TC5UbPD9FOMeta?.name ?? "register-accept-invite-token",
    path: _91token_93TC5UbPD9FOMeta?.path ?? "/register/accept-invite/:token()",
    meta: _91token_93TC5UbPD9FOMeta || {},
    alias: _91token_93TC5UbPD9FOMeta?.alias || [],
    redirect: _91token_93TC5UbPD9FOMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/accept-invite/[token].vue").then(m => m.default || m)
  },
  {
    name: accept_45workspace_45request0ZQBU8JGSwMeta?.name ?? "register-accept-workspace-request",
    path: accept_45workspace_45request0ZQBU8JGSwMeta?.path ?? "/register/accept-workspace-request",
    meta: accept_45workspace_45request0ZQBU8JGSwMeta || {},
    alias: accept_45workspace_45request0ZQBU8JGSwMeta?.alias || [],
    redirect: accept_45workspace_45request0ZQBU8JGSwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/accept-workspace-request.vue").then(m => m.default || m),
    children: [
  {
    name: _91token_93v20Tf91TpoMeta?.name ?? "register-accept-workspace-request-token",
    path: _91token_93v20Tf91TpoMeta?.path ?? ":token()",
    meta: _91token_93v20Tf91TpoMeta || {},
    alias: _91token_93v20Tf91TpoMeta?.alias || [],
    redirect: _91token_93v20Tf91TpoMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/accept-workspace-request/[token].vue").then(m => m.default || m)
  }
]
  },
  {
    name: confirm_45accountl1rnx0IHdKMeta?.name ?? "register-confirm-account",
    path: confirm_45accountl1rnx0IHdKMeta?.path ?? "/register/confirm-account",
    meta: confirm_45accountl1rnx0IHdKMeta || {},
    alias: confirm_45accountl1rnx0IHdKMeta?.alias || [],
    redirect: confirm_45accountl1rnx0IHdKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/confirm-account.vue").then(m => m.default || m)
  },
  {
    name: indexRKmeFaXNHZMeta?.name ?? "register",
    path: indexRKmeFaXNHZMeta?.path ?? "/register",
    meta: indexRKmeFaXNHZMeta || {},
    alias: indexRKmeFaXNHZMeta?.alias || [],
    redirect: indexRKmeFaXNHZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: join_45workspaceUhvFm6PJb4Meta?.name ?? "register-join-workspace",
    path: join_45workspaceUhvFm6PJb4Meta?.path ?? "/register/join-workspace",
    meta: join_45workspaceUhvFm6PJb4Meta || {},
    alias: join_45workspaceUhvFm6PJb4Meta?.alias || [],
    redirect: join_45workspaceUhvFm6PJb4Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/join-workspace.vue").then(m => m.default || m)
  },
  {
    name: new_45workspaceOiDKeKPVfkMeta?.name ?? "register-new-workspace",
    path: new_45workspaceOiDKeKPVfkMeta?.path ?? "/register/new-workspace",
    meta: new_45workspaceOiDKeKPVfkMeta || {},
    alias: new_45workspaceOiDKeKPVfkMeta?.alias || [],
    redirect: new_45workspaceOiDKeKPVfkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/register/new-workspace.vue").then(m => m.default || m)
  },
  {
    name: settings4cDYwTsA31Meta?.name ?? "settings",
    path: settings4cDYwTsA31Meta?.path ?? "/settings",
    meta: settings4cDYwTsA31Meta || {},
    alias: settings4cDYwTsA31Meta?.alias || [],
    redirect: settings4cDYwTsA31Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: accountlrlzqC4yMWMeta?.name ?? undefined,
    path: accountlrlzqC4yMWMeta?.path ?? "account",
    meta: accountlrlzqC4yMWMeta || {},
    alias: accountlrlzqC4yMWMeta?.alias || [],
    redirect: accountlrlzqC4yMWMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/account.vue").then(m => m.default || m),
    children: [
  {
    name: indexYRXphiFWyiMeta?.name ?? "settings-account",
    path: indexYRXphiFWyiMeta?.path ?? "",
    meta: indexYRXphiFWyiMeta || {},
    alias: indexYRXphiFWyiMeta?.alias || [],
    redirect: indexYRXphiFWyiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/account/index.vue").then(m => m.default || m)
  },
  {
    name: notificationsSzxVbhHogNMeta?.name ?? "settings-account-notifications",
    path: notificationsSzxVbhHogNMeta?.path ?? "notifications",
    meta: notificationsSzxVbhHogNMeta || {},
    alias: notificationsSzxVbhHogNMeta?.alias || [],
    redirect: notificationsSzxVbhHogNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/account/notifications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: workspace4fmWmZk1v0Meta?.name ?? "settings-workspace",
    path: workspace4fmWmZk1v0Meta?.path ?? "workspace",
    meta: workspace4fmWmZk1v0Meta || {},
    alias: workspace4fmWmZk1v0Meta?.alias || [],
    redirect: workspace4fmWmZk1v0Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/settings/workspace.vue").then(m => m.default || m)
  }
]
  }
]